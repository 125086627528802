import React from "react"
import { loadStripe } from "@stripe/stripe-js"
import styled from "styled-components"


const stripePromise = loadStripe
("pk_live_yUbiw9egwIOBrUp0qw5LMinI00lu3HVnL8") //live API key
// ("pk_test_4MUazFH1qMyHoCNGO7pC00ax00AbuhgZA6") test API key

const redirectToCheckout = async event => {
  event.preventDefault()
  const stripe = await stripePromise
  const { error } = await stripe.redirectToCheckout({
    lineItems: [{price: 'price_1ICurhLeGZsv0Ux3iVSqW38C', //live
      //'price_1H8FwWLeGZsv0Ux3zRDVqwnu', //test
                quantity: 1}],
    mode: 'subscription', 

    // Do not rely on the redirect to the successUrl for fulfilling
    // purchases, customers may not always reach the success_url after
    // a successful payment.
    // Instead use one of the strategies described in
    // https://stripe.com/docs/payments/checkout/fulfillment
    successUrl: 'https://getfitwithsnehal.com/index.php/thankyou/',
    cancelUrl: 'https://getfitwithsnehal.com.au/#packages',
  })

  if (error) {
    console.warn("Error:", error)
  }
}

const Checkout = () => {
  return (
    <ButtonWrapper onClick={redirectToCheckout}>
    {/* <button onClick={redirectToCheckout}>
      {/* <Button cta="I want this!" /> */}
      I want this!
    {/* </button>  */}
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.button`
  position: relative;
  display: inline-block;
  padding: 0 20px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 0.85rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 30px;
  border: none;
  z-index: 1;

  &:hover {
    cursor: pointer;
    animation: animate 8s linear infinite;

    &::before {
      filter: blur(20px);
      opacity: 1;
      animation: animate 8s linear;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 40px;
    opacity: 0;
    transition: 0.5s;
  }

  @keyframes animate {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
`

export default Checkout