import React from "react"
import Layout from "../components/layout"
import Banner from "../components/Banner/banner"
import TextBlock from "../components/TextBlock/textBlock"
import Perk from "../components/Perk/perk"
import Button from "../components/Button/button"
import Packages from "../components/Packages/packages"
import Package from "../components/Package/package"
import Contact from "../components/Contact/contact"
import Silver from "../components/Checkout/silverCheckout"
// import TenPack from "../components/Checkout/TenPackCheckout"
import Bronze from "../components/Checkout/bronzeCheckout"
// import Gold from "../components/Checkout/goldCheckout"
import Membership from "../components/Checkout/MembershipCheckout"
import { Link } from "react-scroll"

import perk1Img from "../images/speed.svg"
import perk2Img from "../images/piggy-bank.svg"
import perk3Img from "../images/friendly-staff.svg"

import { IconContext } from "react-icons"
import { MdDone } from "react-icons/md"
//import { MdDone, MdClear } from "react-icons/md"

export default () => (
  <>
    <Layout>
      <Banner />
      <TextBlock
        id="about"
        title="Snehal - Group Fitness and Personal trainer"
        paragraph="Certified Fitness trainer, licensed Zumba instructor and Dance Choreograhper (specialised in Bollywood). 
        I absolutely love doing my fitness dance sessions. The reason is simple - My every class feels like a party!"
      >
        <div className="flex-container trio-block">
          <Perk
            img={perk1Img}
            alt="Classes that suit your needs"
            title="Weekly classes"
            content="Virtual BollyZ for adults and Kids DanceFit classes"
          />
          <Perk
            img={perk2Img}
            alt="Great savings to be made"
            title="Affordable"
            content="A choice of packages to suit your budget and needs"
          />
          <Perk
            img={perk3Img}
            alt="Cheerful instructor - Snehal"
            title="Friendly"
            content="Cheerful instructor with 100% attention to every member"
          />
        </div>
        <Link to="packages" smooth={true} duration={500}>
          <Button cta="Join now!" />
        </Link>
      </TextBlock>
      
      <Packages
        title="Our Packages"
        para="Choose the perfect solution for you. With benefits to suit all budgets Snehal can offer amazing value and expert virtual Zumba training."
      >
        <IconContext.Provider
          value={{
            color: "#7FFF00",
            size: "1.2em",
            style: { verticalAlign: "middle", marginRight: "5px" },
          }}
        >
          <Package title="Kids DanceFitness">
            <ul>
              <li>
                <MdDone />
                4 classes per month
              </li>
              <li>
                <MdDone />
                Dance + Exercise + Cool moves
              </li>
              <li >
                <MdDone />
                40 min funtime
              </li>
              <li>
                <MdDone />
                Fridays 5pm
              </li>
              <li>
                Join for $40 per month
              </li>              
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Silver />
              {/* <Button cta="I want this!" /> */}
            </Link>
          </Package>
          <Package title="Ultimate Pack" active={true}>
            <ul>
            <li>
                <MdDone />
                Achieve overall fitness 💪
              </li>              
              <li>
                <MdDone />
                Online personal customised sessions
              </li>
              <li>
                <MdDone />
                Strength, Functional, and Abs workout
              </li>
              <li>
                <MdDone />
                Pre-recorded Zumba videos
              </li>
              <li>
                <MdDone />
                  Nutrition consultations
              </li>
              <li>
                <br/>
                Send Enquiry - 0480 104 902
              </li>              
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              {/* <Membership /> */}
              <Button cta="Contact now" />
            </Link>
          </Package>
          <Package title="Virtual Zumba">
             <ul>
              <li>
                <MdDone />
                Thursdays 6pm
              </li>
              <li>
                <MdDone />
                Bollywood dance fitness
              </li>
              
              <li>
                <MdDone />
                40min non-stop Zumba routine
              </li>
              <li>
                <br/> Join for $10 weekly
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Bronze/>
              {/* <Button cta="I want this!" />  */}
            </Link> 
          </Package>
        </IconContext.Provider>
      </Packages>
      <Contact
        id="contact"
        title="Contact me"
        subtitle="Please leave your name and email to get more details."
      />
    </Layout>
  </>
)
